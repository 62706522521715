.HomeSection__title {
  text-align: center;
  margin-bottom: 2em;
  font-size: 175%;
  position: relative;
}

.HomeSection__title::before {
  content: '';
  position: absolute;
  bottom: -0.5em;
  width: 3em;
  height: 1px;
  background-color: $main-color;
  left: 50%;
  transform: translateX(-50%);
}

.HomeSection p {
  margin-top: 0;
  hyphens: auto;
}

.HomeSection__columns {
  text-indent: 1em;
}

.HomeSection h3 {
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

@media (min-width: 700px) {
  .HomeSection__row {
    display: flex;
    margin: 0 -1em;
  }

  .HomeSection__column {
    flex: 1 1 50%;
    padding: 0 1em;
  }

  .HomeSection__columns {
    columns: 2;
    column-gap: 2em;
  }
}
