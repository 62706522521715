.Layout {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.Layout__main {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-bottom: 4em;
}
