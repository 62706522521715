.Button {
  display: inline-block;
  text-decoration: none;
  padding: 0.5em 1em;
  background-color: white;
  color: $main-color;
  position: relative;
  transition: 180ms;
  border-radius: 2px;
  text-align: center;
}

.Button + .Button {
  margin-top: 0.75em;
}

.Button:hover {
  background-color: $main-color;
  color: white;
}

@media (min-width: 700px) {
  .Button + .Button {
    margin-top: 0;
    margin-left: 0.75em;
  }
}
