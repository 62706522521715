.Nav {
  list-style: none;
  padding: 0;
  margin: 0 -0.25em;
}

.Nav__item {
  display: inline-block;
  padding: 0 0.25em;
}

.Nav__link {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  position: relative;
}

.Nav__link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: currentcolor;
  transform: scaleX(0);
  transition: transform 180ms;
}

.Nav__link:hover::before {
  transform: scaleX(1);
}
