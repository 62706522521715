.Footer {
  text-align: center;
}

@media (min-width: 700px) {
  .Footer {
    text-align: left;
  }

  .Footer__container {
    display: flex;
  }

  .Footer__nav {
    margin-left: auto;
  }
}
