.ArticleList__list {
  padding-left: 0;
  list-style: none;
}

.ArticleList__item {
  margin-bottom: 1em;
}

.ArticleList__meta {
  display: block;
}

.ArticleList__link {
  display: inline-block;
  color: $main-color;
  text-decoration: none;
  position: relative;
}

.ArticleList__link::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: currentcolor;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 250ms;
}

.ArticleList__link:hover::after {
  transform: scaleX(1)
}

.ArticleList__subscribe {
  color: rgb(50, 50, 50);
  font-size: 80%;
}
