*, ::before, ::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

html, body {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

body {
  font: 120% / 1.5 Roboto Slab, sans-serif;
  margin: 0;
}

a {
  color: $main-color;
}

abbr {
  text-decoration: none;
  border-bottom: 1px dotted;
  cursor: help;
}
