.Masthead {
  background-image: var(--background);
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 100%;
  min-height: calc(100vh - 66px);
}

.Masthead::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(45deg, rgba(54, 148, 244, 0.88),rgba(255, 105, 142, 0.88));
  opacity: .8;
}

.Masthead__container {
  width: 1100px;
  margin: auto;
  padding: 1em;
  color: white;
  position: relative;
  z-index: 2;
  max-width: 100%;
}

.Masthead__title {
  margin-bottom: .5em;
}

.Masthead__subline {
  margin-bottom: 2em;
  max-width: 50ch;
  font-size: 120%;
}
