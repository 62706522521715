.Header {

}

.Header__container {
  display: flex;
  position: relative;
}

.Header__nav {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  background-color: white;
  padding: 1em;
  z-index: 2;
}

.Header__nav[aria-hidden="true"] {
  display: none;
}

.Header__toggle {
  margin-left: auto;
  background-color: transparent;
  padding: 0;
  border: 0;
  font: inherit;
}

@media (min-width: 700px) {
  .Header__toggle {
    display: none;
  }

  .Header__nav {
    position: static;
    margin-left: auto;
    padding: 0;
  }
}
